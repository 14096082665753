<!--
	@name app-source-result-dialog-websocket-error
	@description Dialog component for displaying websocket error
	@date 2022/03/01
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-dialog ref="dialog" :show-close="false" persistent>
		<template #header>
			{{ title }}
		</template>

		<template #body>
			<div>
				<p class="text-subtitle-1 mb-0">
					We have been unable to establish a connection to our dynamic server. This is usually related to network/security settings on your local environment. Click below
					to revert to our standard server and continue your sourcing journey.
				</p>
			</div>
		</template>

		<template #footer>
			<div class="pa-4">
				<v-btn :disabled="retryAttempts === 3" color="success" class="mr-4" :loading="retryInProgress" @click="retryWebSocket">
					Retry
				</v-btn>
				<v-btn :text="retryAttempts !== 3" color="primary" class="mr-4" @click="switchToStandardServer">
					Switch to standard server
				</v-btn>
			</div>
		</template>
	</common-dialog>
</template>

<script>
	import CommonDialog from '@/component/common/dialog';

	export default {
		name: 'app-source-result-dialog-websocket-error',

		components: { CommonDialog },

		props: {
			retryInProgress: { type: Boolean, required: true },
			retryAttempts: { type: Number, required: true }
		},

		data() {
			return {
				title: 'Error'
			};
		},

		methods: {
			/**
			 * @name open
			 * @description Open the dialog
			 */
			open(title) {
				this.title = title;
				this.$refs.dialog.open();
			},

			close() {
				this.$refs.dialog.close();
			},

			/**
			 * @name retryWebSocket
			 * @description Retry the websocket connection
			 */
			retryWebSocket() {
				this.$emit('retry-websocket');
			},

			/**
			 * @name switchToStandardServer
			 * @description Switch to the standard server
			 */
			switchToStandardServer() {
				this.$emit('switch-to-standard-server');
			}
		}
	};
</script>
